<template>
  <div>
    <div class="d-flex align-items-center">
      <h1 class="h3">
        {{ `${$t('adjustment.stock')}` }}
      </h1>
      <div class="ml-auto">
        <a-button
          v-if="!baseUrl.includes('admin.powerbiz.asia') && permission.includes('WRITE')"
          type="primary"
          size="large"
          class="mr-3"
          ghost
          :loading="isSyncing"
          :disabled="isLoading"
          @click="onChangeModal(true)"
        >
          {{ $t('adjustment.sync') }}
        </a-button>
        <a-button
          v-if="params.warehouse_id && permission.includes('WRITE') && !flagWms"
          type="primary"
          size="large"
          :disabled="isLoading"
          @click="toCreateAdjustment"
        >
          <a-icon type="plus-square" />
          {{ $t('adjustment.create') }}
        </a-button>
        <div v-if="!baseUrl.includes('admin.powerbiz.asia') && params.warehouse_id && !!message" class="mt-2">
          {{ message }} 
          <a-spin v-if="isChecking">
            <a-icon slot="indicator" type="loading" spin />
          </a-spin>
          <template v-else>
            {{ result }}
          </template>
        </div>
      </div>
    </div>
    <a-row :gutter="16">
      <a-col v-if="restriction === 'Business'" :span="6">
        <a-select 
          v-if="restriction === 'Business'"
          v-model="params.warehouse_id" 
          allow-clear
          show-search
          size="large" 
          class="w-100 mt-4 select-antd-default" 
          option-filter-prop="children"
          :filter-option="filterOption"
          :placeholder="$t('productAvailability.gudang')"
          :options="warehouseList"
          :disabled="isLoading"
          @change="handleChangeWarehouse"
        />
        <a-alert
          v-if="!params.warehouse_id"
          class="mt-2"
          :message="$t('productAvailability.warningAdjustment')"
          type="warning"
          show-icon
        />
      </a-col>
      <a-col :span="8">
        <a-input-search
          v-model="params.search" 
          allow-clear
          size="large"
          class="w-100 mt-4"
          :enter-button="$t('utils.search')"
          :placeholder="$t('adjustment.search')"
          :loading="isLoading"
          @search="fetchAdjustment"
        />
      </a-col>
      <a-col :span="6" :offset="4" class="text-right">
        <div class="d-flex align-items-center flexno text-right mt-4">
          <b class="mr-3 ml-4">
            {{ $t('order.sortBy') }}
          </b>
          <a-select
            v-model="params.sort"
            style="width: 150px"
            size="large"
            class="select-antd-default"
            :options="sorts"
            @change="fetchAdjustment"
          />
        </div>
      </a-col>
    </a-row>
    <a-table
      class="adjustment-list mt-4"
      :data-source="adjustmentList"
      :row-key="record => record.id"
      :columns="columns"
      :loading="isLoading"
      :pagination="false"
      :scroll="scroll"
    >
      <template slot="warehouse_id" slot-scope="text">
        {{ getWarehouseName(text) }}
      </template>
      <template slot="date" slot-scope="text">
        {{ $moment(text).format('DD MMMM YYYY') }}
      </template>
      <template slot="type" slot-scope="text">
        {{ tipe[text] }}
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        v-model="params.page"
        :page-size="params.size"
        :page-size-options="sizeOptions"
        :total="totalRow"
        :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
        :disabled="isLoading"
        show-size-changer
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>
    <a-modal
      :title="$t('adjustment.sync')"
      :width="500"
      :mask-closable="false"
      :visible="visible"
      :dialog-style="{ top: '20px' }"
      @ok="onSyncStock"
      @cancel="onChangeModal(false)"
    >
      {{ $t('adjustment.choose_sync_warehouse') }}
      <a-select 
        v-if="restriction === 'Business'"
        v-model="selectedWarehouses"
        allow-clear
        show-search
        label-in-value
        mode="multiple"
        class="w-100 mt-2 select-antd-default" 
        option-filter-prop="children"
        :filter-option="filterOption"
        :placeholder="$t('adjustment.all_warehouse')"
        :options="warehouseList"
      />
    </a-modal>
  </div>
</template>

<script>
import { columsAdjustment } from '@/data/columns'
import { getStockAdjustment, checkSyncStock } from '@/api/inventory'
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      columns: columsAdjustment,
      message: null,
      result: null,
      visible: false,
      isLoading: false,
      isChecking: false,
      totalRow: 10,
      warehouseList: [],
      adjustmentList: [],
      selectedWarehouses: [],
      sizeOptions: ['10', '20', '30', '40', '50'],
      sorts: [
        { value: 'date,Desc', label: this.$t('order.sortByNewest') },
        { value: 'date,Asc', label: this.$t('order.sortByOldest') },
      ],
      tipe: {
        ADJIN: 'Adjust In',
        ADJOUT: 'Adjust Out',
      },
      params: {
        warehouse_id: this.$route.query.warehouse_id || undefined,
        search: '',
        business_id: this.$route.query.business_id
          ? this.$route.query.business_id
          : Object.keys(this.$store.getters['user/businessListObjectByKey'])[0],
        sort: 'date,Desc',
        page: 1,
        size: 10,
      },
      flagWms: false,
    }
  },
  computed: {
    baseUrl() {
      return window.location.href
    },
    restriction() {
      return this.$store.state.user.restriction_base
    },
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    isSyncing() {
      return this.$store.state.warehouse.syncing
    },
    syncWarehouses() {
      return this.$store.state.warehouse.syncWarehouses
    },
    screenHeight() {
      return ((screen.height - 900) * 0.5) + (screen.height * 0.5)
    },
    scroll() { 
      return { y: this.screenHeight > 450 ? this.screenHeight : 450, x: 1000 }
    },
    tHeight() {
      return !this.adjustmentList.length ? '0px' : this.screenHeight > 450 
        ? `${this.screenHeight}px` : '450px'
    },
    pHeight() {
      return this.adjustmentList && this.adjustmentList.length ? '0px' : this.screenHeight > 450 
        ? `${this.screenHeight}px` : '450px'
    },
    permission() {
      return this.$store.getters['user/can']('adjustment')
    },
  },
  watch: {
    '$route.query'() {
      this.fetchWarehouseList()
    },
    syncWarehouses(newVal, oldVal) {
      if(newVal.length && !oldVal.length && this.params.warehouse_id && newVal.includes(this.params.warehouse_id)) {
        this.result = null
        this.checkSync()
      } else if(!newVal.length && oldVal.length && this.params.warehouse_id && oldVal.includes(this.params.warehouse_id)) {
        this.checkSync()
      }
    },
    'params.warehouse_id'(value) {
      const filter = this.warehouseList.filter(v => {return v.value === value})
      this.flagWms = filter?.[0]?.wms_code === null || filter?.[0]?.wms_code === 'N/A' || filter?.[0]?.wms_code === '' ? false : true
      if(!window.location.href.includes('admin.powerbiz.asia')) this.checkSync()
    },
    businessId(newValue) {
      this.params.business_id = newValue
      this.fetchWarehouseList()
    },
  },
  mounted() {
    this.fetchWarehouseList()
  },
  methods: {
    fetchWarehouseList: debounce(async function() {
      await this.$store
        .dispatch('warehouse/GETWAREHOUSELIST', {
          ...this.$route.query,
          business_id: this.businessId,
        })
        .then(response => {
          this.warehouseList = response
          this.params.warehouse_id = this.$route.query.warehouse_id || this.$route.query.warehouseId || undefined
          if(!this.params.warehouse_id && response.length == 1) {
            this.params.warehouse_id = response[0].id
            this.$router.push({
               query: { ...this.$route.query, warehouseId: response[0].id, page: 1 },
            })
          }
          this.fetchAdjustment()
        })
        .catch(err => {
          this.warehouseList = []
          console.error(err)
        })
    }, 500),
    handleChangeWarehouse(value) {
      this.params.warehouse_id = value
      this.$router.push({
        query: { ...this.$route.query, warehouseId: value, page: 1 },
      })
    },
    async fetchAdjustment() {
      this.isLoading = true
      
      await getStockAdjustment(this.params)
        .then(({ data }) => {
          this.adjustmentList = data.content
          this.totalRow = data.total_of_elements
        })
        .catch(err => {
          this.adjustmentList = []
          this.totalRow = 0
          console.error(err)
        })
        .finally(() => this.isLoading = false)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
      )
    },
    getWarehouseName(warehouse_id) {
      if(!warehouse_id) return '-'
      const findWarehouse = this.warehouseList.find(item => item.value === warehouse_id)
      return findWarehouse?.label || '-'
    },
    onChangeModal(value) {
      this.visible = value
      this.selectedWarehouses = []
    },
    onSyncStock() {
      const warehouses = !this.selectedWarehouses.length
        ? this.warehouseList.map(warehouse => ({
            key: warehouse.id,
            label: warehouse.name,
          }))
        : this.selectedWarehouses
      this.$store.dispatch('warehouse/SYNCWAREHOUSESTOCK', {
        business_id: this.businessId,
        warehouses,
      })
      this.onChangeModal(false)
    },
    checkSync() {
      if(this.syncWarehouses.includes(this.params.warehouse_id)) {
        this.message = `${this.$t('adjustment.is_syncing')}...`
      } else {
        this.message = `${this.$t('adjustment.last_sync')}: `
        this.isChecking = true
        this.onCheckSyncStock()
      }
    },
    onCheckSyncStock: debounce(async function() {
      await checkSyncStock({
        business_id: this.businessId,
        params: {
          warehouse_id: this.params.warehouse_id,
        },
      })
      .then(({ data }) => {
        if(data.status === 'ONPROGRESS') {
          this.onCheckSyncStock()
        } else {
          this.result = data?.end
            ? this.$moment(data.end).format('DD MMMM YYYY HH:MM')
            : null
          this.isChecking = false
        }
      })
      .catch(err => {
        this.isChecking = false
        console.error(err)
      })
    }, 1000),
    onPageChange(pageNumber) {
      this.params.page = pageNumber
      this.fetchAdjustment()
    },
    onShowSizeChange(current, pageSize) {
      this.params.page = current
      this.params.size = pageSize
      this.fetchAdjustment()
    },
    resetParams() {
      this.params = {
        warehouse_id: this.$route.query.warehouse_id || undefined,
        search: '',
        business_id: this.$route.query.business_id
          ? this.$route.query.business_id
          : Object.keys(this.$store.getters['user/businessListObjectByKey'])[0],
        sort: 'date,Desc',
        page: 1,
        size: 10,
      }
    },
    toCreateAdjustment() {
      this.$router.push({
        name: 'inventory.stock.adjustment.create',
        query: {
          ...this.$route.query,
          warehouseId: this.$route.query.warehouse_id || this.$route.query.warehouseId,
        },
      })
    },
  },
}
</script>

<style lang="scss">
.adjustment-list {
  div {
    .ant-table-body {
      min-height: v-bind(tHeight);
    }
    .ant-table-placeholder {
      min-height: v-bind(pHeight);
    }
  }
  .list-title {
    font-weight: bold;
  }
  .list-info {
    font-size: 12px;
  }
}
.minus {
  color: #ff0a54;
}
.pca-tab-store {
  .ant-tabs-bar {
    background-color: #fff;
    margin-bottom: 0;
    border-bottom-color: transparent;
  }
  .ant-tabs-nav .ant-tabs-tab:hover,
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff0a54;
  }
  .ant-tabs-ink-bar {
    background-color: #ff0a54;
  }
}
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
</style>
